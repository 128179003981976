import { Descriptions } from "antd";
import './os.css';
import { marked } from 'marked';
import { useEffect } from "react";

export function OS(props: any) {
    return (

        <div className="os">
            {
                'items' in props &&
                <Descriptions layout="horizontal" title={`Resumo da Abertura do Chamado:\n\n ${props.description}`} column={1} bordered>
                    {
                        props.items.map((item: any, index: number) => {
                            const labelAux = `Item: ${item.numItem}\n${item.dataInicio}\nInício: ${item.horaInicio}\nFim: ${item.horaFim}\n${item.nomeUsu}`;
                            return <Descriptions.Item key={`item-${index}`} label={`${labelAux}`}>{item.solucao}</Descriptions.Item>;
                        })
                    }
                </Descriptions>
            }
        </div>

    );
}