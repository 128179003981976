import {  Col, Layout, Row } from 'antd';
import 'antd/dist/antd.min.css';
import 'App.css';
import { Content } from 'antd/lib/layout/layout';
import { AutoCompleteInput } from '../autocomplete-component';
import { Footer } from 'commons/components/footer-component';
import { OptIn } from '../opt-in-component';
import { useEffect, useState } from 'react';
import OptInService from 'services/optIn';

export function Home() {

  const [showModal, setShowModal] = useState(false); 

  useEffect(() => {
    document.title = 'Busca Sankhya';
    
    OptInService.checkOptIn().then((res: any) => {
      setShowModal(res);
    });

  }, []);

    return (
        
        <div className='search'>
          <OptIn setShowModal={setShowModal} showModal={showModal}/>
          <Layout style={{backgroundColor: 'transparent', height: '100%', width: '100%'}}>
            <Content style={{height: '100%'}}>
              <div className='App-autocomplete'>
                <Row className='margin-top-search' gutter={[24, 24]}>
                  <Col className="gutter-row" span={24}>
                    <h1 className='h1-search'>Como podemos te ajudar?</h1>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <AutoCompleteInput page='home' query='' handleResetQuery={() => {}}/>
                  </Col>
                </Row>
              </div>
            </Content>
            <Footer page='home'/>
          </Layout>
        </div>
    )
}