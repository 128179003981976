import { Tag } from 'antd';
import './result-card-info.css';
import IconHelpCenter from "../../icons/IconHelpCenter";
import IconOS from "../../icons/IconOS";
import IconTicket from "../../icons/IconTickets";
import IconTecNotes from "../../icons/IconTecNotes";
import { ITecNotes } from '../tec-notes-component';
import { CalendarOutlined, FolderOpenOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DocumentViewService from '../../../services/documentsView';

export function ResultCardInfo(props: ITecNotes | any) {
    let { typeDoc, idCard } = useParams();
    let canOpenInNewTab;
    const { state } = useLocation();
    let DOC_URL = `${process.env.REACT_APP_URL_API}/api/docs/documents/${idCard}`;
    let ZENDESK_URL = `${process.env.REACT_APP_ZENDESK_URL}/articles/${idCard}`;

    const typeDocName: any = {
        'articles': 'Central de Ajuda',
        'os': 'OS',
        'tickets': 'Tickets',
        'notes': 'Notas Técnicas'
    };

    const typeDocIcon: any = {
        'articles': <><span className='anticon'><IconHelpCenter className='type-doc-icon' /></span></>,
        'os': <><span className='anticon'><IconOS className='type-doc-icon' /></span></>,
        'tickets': <><span className='anticon'><IconTicket className='type-doc-icon' /></span></>,
        'notes': <><span className='anticon'><IconTecNotes className='type-doc-icon' /></span></>
    };

    const typeDocUrl: any = {
        'articles': ZENDESK_URL,
        'notes': DOC_URL,
    }

    const typeDocLinkName: any = {
        'articles': 'Ver artigo no Zendesk',
        'notes': 'Abrir link em nova guia'
    }

    if (typeDoc === 'articles' || typeDoc === 'notes') {
        canOpenInNewTab = true;
    }

    return (
        <>
            {Object.keys(props).length !== 0 &&
                <div className="result-card-info">
                    {
                        canOpenInNewTab &&
                        <a target='_blank' className='openInNewTab' href={`${typeDocUrl[typeDoc!]}`}>
                            {`${typeDocLinkName[typeDoc!]}`}
                        </a>
                    }

                    {
                        'related_os' in props && props.related_os &&
                        <span>OS relacionada: <Link 
                                                state={{
                                                    query: state !== null && state.query !== undefined ? state.query : '', 
                                                    idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '', 
                                                    timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : '',
                                                    prevURL: state !== null && state.prevURL !== undefined ? state.prevURL : '' }}
                        to={`/docs/os/${encodeURIComponent(props.related_os)}`}>{props.related_os}</Link>
                        <br />
                        </span>                        
                    }                    

                    {
                        'related_ticket' in props && props.related_ticket &&
                        <span>Ticket relacionado: <Link 
                                                    state={{
                                                        query: state !== null && state.query !== undefined ? state.query : '', 
                                                        idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '', 
                                                        timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : '',
                                                        prevURL: state !== null && state.prevURL !== undefined ? state.prevURL : '' }}
                        to={`/docs/tickets/${encodeURIComponent(props.related_ticket)}`}>{props.related_ticket}</Link></span>
                    }

                    <div className="result-card-info-title">
                        <h1>{props.title}</h1>
                    </div>

                    <div className="result-card-info-details">
                        {typeDoc &&
                            <Tag className='type-doc-tag' icon={typeDocIcon[typeDoc!]}>{typeDocName[typeDoc!]}</Tag>
                        }

                        {props.section_name &&
                            <Tag className='section-tag' icon={<FolderOpenOutlined />}>{typeDoc === 'articles' ? props.categoryName + '/' + props.section_name : props.section_name}</Tag>
                        }

                        {props.partner_name &&
                            <Tag className='partner-tag' icon={<UserOutlined />}>{props.partner_name}</Tag>
                        }

                        {props.updated_at &&
                            <Tag className='date-tag' icon={<CalendarOutlined />}>{props.updated_at}</Tag>
                        }

                        {
                            'status' in props &&
                            <Tag className='status-tag'>Status: {props.status === 'closed' ? 'Concluído' : 'Aberto'}</Tag>
                        }

                    </div>
                </div>
            }
        </>
    )
}