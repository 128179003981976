import { AutoCompleteInput } from "../autocomplete-component/autocomplete";
import LineLogoSankhya from "../../icons/line-logo-sankhya.svg";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './result-card-header.css';
import IconArrowBack from '../../icons/IconArrowBack';

export default function ResultCardHeader() {
    const [idSearch, setIdSearch] = useState("");
    const [timestampSearch, setTimestampSearch] = useState("");
    const { state } = useLocation();
    const prevUrl = state !== null && state.prevURL !== undefined ? state.prevURL : '';
    const navigate = useNavigate();
    const query = state !== null && state.query !== undefined ? state.query : '';

    const handleResetQuery = (): void => {
        setTimestampSearch("");
        setIdSearch("");
    }

    return (
        <div className="result-card-header">
            <div className='img-background'>
                <div id='search-section'>
                    <div id='col-autocomplete-and-previous-page'>
                        <AutoCompleteInput handleResetQuery={handleResetQuery} query={query } page='visResult' />

                        {
                            prevUrl &&
                            <div className="back-results" onClick={() => { 
                                    sessionStorage.setItem('scrollState', state.scrollState);
                                    navigate(prevUrl); 
                                }}>
                                <IconArrowBack className="icon-arrow-back" />
                                <h2>Voltar para os resultados da pesquisa</h2>
                            </div>
                        }

                    </div>

                </div>

            </div>

        </div>

    );
}