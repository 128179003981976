import 'antd/dist/antd.min.css';
import 'App.css';
import '../search-result-item-card-component/search-result-item-card.css';
import { CalendarOutlined, UserOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import IconHelpCenter from "../../icons/help-center.svg";
import IconOs from "../../icons/os.svg";
import IconTicket from "../../icons/tickets.svg";
import IconTecNotes from "../../icons/tec-notes.svg";
import DateUtils from '../../utils/date';

interface Props {
    typeDoc: string,
    sectionName: string,
    partnerName: string,
    date: string,
    description: string[],
    url: string,
    title: string,
    categoryName: string
    idSearch: string,
    timestampSearch: string,
    docPosition: number,
    totalResults: number
}

export const SearchResultItemCard: React.FC<Props> = ({ typeDoc, sectionName, partnerName, date, description, url, title, categoryName, idSearch, timestampSearch, docPosition, totalResults }) => {
    const { query } = useParams();
    const navigate = useNavigate();

    const mapTypeDocToIcon:any = {
        'ART': IconHelpCenter,
        'OS': IconOs,
        'TCK': IconTicket,
        'NTC': IconTecNotes
    };

    const mapTypeDocToIdCard:any = {
        'ART': 'articles',
        'OS': 'os',
        'TCK': 'tickets',
        'NTC': 'notes'
    };

    const mapTypeDocToName:any = {
        'ART': 'Central de Ajuda',
        'OS': 'OS',
        'TCK': 'Tickets',
        'NTC': 'Notas Técnicas'
    };
    return (
        <div className='search-result-item-card'>
            <div className='header-search-result-item-card'>
                <div className='type-card-header search-result-item'>
                    <img src={mapTypeDocToIcon[typeDoc]}></img>
                    <span> { mapTypeDocToName[typeDoc] }</span>
                </div>
                <div className='category-header search-result-item'>
                    <FolderOpenOutlined />
                    <span>{ typeDoc === 'ART' ? categoryName + '/' + sectionName : sectionName }</span>
                </div>
                { partnerName !== '' &&
                    <div className='partner-header search-result-item'>
                        <UserOutlined />
                        <span>{ partnerName }</span>
                    </div>
                }
                
                <div className='last-update-header search-result-item'>
                    <CalendarOutlined />
                    <span>{ DateUtils.convertDate(date) }</span>
                </div>
            </div>

            <a  onClick={() => { 
                    navigate(`/docs/${mapTypeDocToIdCard[typeDoc]}/${encodeURIComponent(url)}`,
                    { 
                        state: {  
                            query: query, 
                            idSearch: idSearch, 
                            timestampSearch: timestampSearch, 
                            prevURL: window.location.pathname + window.location.search,
                            scrollState: window.scrollY,
                            docPosition: docPosition,
                            totalResults: totalResults,
                            categoryName: categoryName
                        }
                    });
                 }}
            >

                <h1 className='title-search-result-item-card' dangerouslySetInnerHTML={{ __html: title }} />

            </a>

            <div className='content-search-result-item-card'>

                {
                    description.slice(0, 2).map((text:string, index:number) => (
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                    ))
                }
                
            </div>
        </div>
    )
}