interface Props {
    className?: string;
}

export default function IconArrowBack(props: Props) {
    return (
        <svg className={props.className} width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M19.1525 15C18.9077 15 18.7053 14.9224 18.5452 14.7673C18.3851 14.6122 18.3051 14.4161 18.3051 14.1788V10.2372C18.3051 9.25182 17.9473 8.41241 17.2316 7.71898C16.516 7.02555 15.6497 6.67883 14.6328 6.67883H2.88136L6.66667 10.3467C6.81733 10.4927 6.89265 10.6752 6.89265 10.8942C6.89265 11.1131 6.80791 11.3047 6.63842 11.469C6.46893 11.6332 6.27119 11.7153 6.0452 11.7153C5.81921 11.7153 5.62147 11.6332 5.45198 11.469L0.254237 6.43248C0.160075 6.34124 0.0941619 6.25 0.0564971 6.15876C0.0188323 6.06752 0 5.96715 0 5.85766C0 5.74817 0.0188323 5.64781 0.0564971 5.55657C0.0941619 5.46533 0.160075 5.37409 0.254237 5.28285L5.48023 0.218978C5.63088 0.0729925 5.81921 0 6.0452 0C6.27119 0 6.46893 0.0821167 6.63842 0.24635C6.80791 0.410584 6.89265 0.60219 6.89265 0.821168C6.89265 1.04015 6.80791 1.23175 6.63842 1.39598L2.88136 5.0365H14.6328C16.1017 5.0365 17.3635 5.54288 18.4181 6.55566C19.4727 7.56843 20 8.79562 20 10.2372V14.1788C20 14.4161 19.92 14.6122 19.7599 14.7673C19.5998 14.9224 19.3974 15 19.1525 15Z" 
                fill="#2B3A54"
            />
        </svg>

    );
}