import SuccessIcon from "../icons/checkIcon";
import ErrorIcon from "../icons/errorIcon";
import InfoIcon from "../icons/infoIcon";
import {toast} from "react-toastify";

class Message {

    showSuccess(message) {
        return this.configMessage(message, "success");
    }

    showWarning(message) {
        return this.configMessage(message, "warning");
    }

    showInfo(message) {
        return this.configMessage(message, "info");
    }

    showError(message, exception = null) {
        return this.configMessage(message, "error");
    }

    configMessage(message, severity) {
        toast(buildBody(severity, message));
    }
}

function getDiv(severity) {
    switch (severity) {
        case 'success':
            return (
                <>
                    <SuccessIcon className={"toast-icon"}/>
                    Sucesso
                </>
            );
        case 'error':
            return (
                <>
                    <ErrorIcon className={"toast-icon"}/>
                    Erro
                </>
            );
        default:
            return (
                <>
                    <InfoIcon className={"toast-icon"}/>
                    Aviso
                </>
            );
    }
}

function buildBody(severity, message) {
    const messageBody = (
        <div className={"toast-body"}>
            <div className={"toast-icon-box"}>
                {getDiv(severity)}
            </div>
            <span>{message}</span>
        </div>
    );
    return messageBody
}

export default new Message();
