interface Props {
    className?: string;
}

export default function IconTickets(props: Props) {
    return (
        <svg width="19" className={props.className} height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M1.41502 9.24389C0.634548 9.88684 0.522738 11.0441 1.16569 11.8246L2.6228 13.5934C3.20871 13.1107 4.07566 13.1945 4.55833 13.7804C5.041 14.3663 4.95724 15.2332 4.37133 15.7159L5.82844 17.4847C6.47139 18.2651 7.62868 18.377 8.40915 17.734L18.3143 9.57419C19.0948 8.93124 19.2066 7.77395 18.5636 6.99348L17.1065 5.2247C16.5206 5.70737 15.6536 5.62361 15.171 5.03771C14.6883 4.4518 14.7721 3.58484 15.358 3.10217L13.9009 1.3334C13.2579 0.552928 12.1006 0.441118 11.3202 1.08407L1.41502 9.24389ZM4.57857 10.2007L8.07564 14.4458L15.1507 8.61735L11.6537 4.37229L4.57857 10.2007ZM3.87106 10.7836C3.54868 10.3922 3.60439 9.81561 3.99573 9.49322L11.0708 3.66478C11.4622 3.34239 12.0388 3.3981 12.3612 3.78944L15.8582 8.0345C16.1806 8.42584 16.1249 9.00247 15.7336 9.32486L8.65848 15.1533C8.26714 15.4757 7.69051 15.42 7.36813 15.0286L3.87106 10.7836Z" 
                fill="currentColor"
            />
        </svg>
    );
}