import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.min.css';
import './card.css';
import { TecNotes } from '../tec-notes-component';
import { ResultCardInfo } from '../result-card-info-component';
import { useLocation, useParams } from 'react-router-dom';
import ResultCardHeader from '../result-card-header/result-card-header';
import { Footer } from '../footer-component/footer';
import documentsView from 'services/documentsView';
import { OS } from '../os-component';
import { Tickets } from '../tickets-component';
import { RelatedContent } from '../related-content-component';
import { HelpCenter } from '../help-center-component';
import { NotAvailable } from '../not-available-component';
import DateUtils from '../../utils/date';

interface Props {
    typeDoc: string,
    section_name: string,
    partner_name: string,
    date: string,
    description: string[],
    url: string,
    title: string
}

export function Card() {
    const notAvailableTicket = 'Este ticket estará aqui em breve! Tente atualizar a página';
    const notAvailableOS = 'Esta OS estará aqui em breve!';
    const { typeDoc, idCard } = useParams();
    const { state } = useLocation();
    const [card, setCard] = useState({} as Props | any);
    const [notAvailable, setNotAvailable] = useState(false);

    const getTypeDocProps = (data: any) => {
        switch (typeDoc) {
            case 'articles':
                return {
                    updated_at: DateUtils.convertDate(data.content.updated_at),
                    section_name: data.content.section_name,
                    url_zendesk: data.content.url_zendsk,
                    related_docs: data.related_docs,
                    title: data.content.title,
                    body: data.content.body,
                    idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '',
                    query: state !== null && state.query !== undefined ? state.query : '',
                    timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : '',
                    categoryName: state !== null && state.categoryName !== undefined ? state.categoryName : ''
                }

            case 'notes':
                return {
                    updated_at: data.date,
                    section_name: data.section_name,
                    related_docs: data.related_docs,
                    title: data.title,
                    url_for: data.url_for,
                    idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '',
                    query: state !== null && state.query !== undefined ? state.query : '',
                    timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : ''
                }

            case 'os':
                return {
                    updated_at: data.content.source.DTALTER.split(' ')[0],
                    section_name: data.content.source.produto,
                    related_docs: data.related_docs,
                    title: `Ordem de serviço: ${data.content.source.numOS}, aberta em ${data.content.source.dataAbertura}`,
                    description: data.content.description,
                    status: data.content.status,
                    partner_name: data.content.source.nomeParc,
                    items: data.content.source.items,
                    opening_date: data.content.source.dataAbertura,
                    related_ticket: data.content.source.ticket,
                    related_os: data.content.source.NUMOSRELACIONADA,
                    idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '',
                    query: state !== null && state.query !== undefined ? state.query : '',
                    timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : ''
                }

            case 'tickets':
                return {
                    updated_at: DateUtils.convertDate(data.content.source.updated_at),
                    section_name: data.content.source.product_name,
                    related_docs: data.related_docs,
                    title: `Ticket: ${data.content.source._id}, aberto em ${data.content.source.created_at}, ${data.content.source.created_time}`,
                    description: data.content.description,
                    status: data.content.status,
                    partner_name: data.content.source.nome_parc,
                    comments: data.content.source.comments,
                    opening_date: `${data.content.source.created_at} ${data.content.source.created_time}`,
                    related_os: data.content.source.related_os,
                    idSearch: state !== null && state.idSearch !== undefined ? state.idSearch : '',
                    query: state !== null && state.query !== undefined ? state.query : '',
                    timestampSearch: state !== null && state.timestampSearch !== undefined ? state.timestampSearch : ''
                }
        }
    }

    function getCard(query: string, idSearch: string, timestampSearch: string, docPosition: number, totalResults: number) {
        documentsView.getDocument(idCard, typeDoc, query, idSearch, timestampSearch, docPosition, totalResults).then((res) => {
            if ((res.title !== undefined && res.title.length > 0) || res.content.title !== undefined) {
                document.title = res.title !== undefined ? res.title : res.content.title;
                setCard(getTypeDocProps(res));

            } else if (res === notAvailableOS || res === notAvailableTicket) {
                setNotAvailable(true);

            } else {
                setNotAvailable(true);
            }
        }).catch((err) => {
            setNotAvailable(true);
            console.error(err);
        });
    }

    useEffect(() => {
        let query = state !== null && state.query !== undefined ? state.query : '';
        let idSearch = state !== null && state.idSearch ? state.idSearch : '';
        let totalResults = state !== null && state.totalResults ? state.totalResults : null;
        let docPosition = state !== null && state.docPosition ? state.docPosition : null;
        let timestampSearch = state !== null && state.timestampSearch !== undefined ? state.timestampSearch : '';
        getCard(query, idSearch, timestampSearch, docPosition, totalResults);
    }, [window.location.href]);
    return (
        <>
            <div className='card'>
                <ResultCardHeader />
                <section className='card-content'>
                    <div className='doc-content'>
                        <ResultCardInfo {...card} />
                        {notAvailable === false && typeDoc === 'notes' && <TecNotes />}
                        {notAvailable === false && typeDoc === 'os' && <OS {...card} />}
                        {notAvailable === false && typeDoc === 'tickets' && <Tickets {...card} />}
                        {notAvailable === false && typeDoc === 'articles' && <HelpCenter {...card} />}
                        {notAvailable === true && <NotAvailable />}
                    </div>
                    <RelatedContent {...card} />
                </section>
            </div>
            <Footer page='visResult'></Footer>
        </>
    );
}
