import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function SankhyaHub(){
    const APPLICATION_ID = 16;
    const URL_LOGO_SANKHYA = process.env.REACT_APP_LOGO_SANKHYA;
    const navigate = useNavigate();

    const showTooltip = (e: any) => {
        e.target.setAttribute('title', 'Ir para página inicial');
    };

    useEffect(() => {
        const hub = document.querySelector("sankhya-hub");
        const shadowRoot = hub?.shadowRoot;
        const imgElement = shadowRoot?.querySelector("img");
        imgElement?.addEventListener('click', () => {
            navigate(`/`);
        });

        imgElement?.addEventListener('mouseenter', showTooltip);
    });

    
    let SankhyaHub = function SankhyaHub(props: JSON | Object | any) {
        return React.createElement("sankhya-hub", {
            background: "#ffffff",
            id: "hub",
            applicationId: props.applicationId,
            style: {width: '100%', position: 'relative', zIndex: 2},
            brandUrl: URL_LOGO_SANKHYA,
        });
    };
    
    return (
        <SankhyaHub applicationId={APPLICATION_ID} />
    )
}
