interface Props {
    className?: string;
}

export default function IconHelpCenter(props: Props) {
    return (
        <svg className={props.className} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M3 0C1.34375 0 0 1.34375 0 3V13C0 14.6562 1.34375 16 3 16H12H13C13.5531 16 14 15.5531 14 15C14 14.4469 13.5531 14 13 14V12C13.5531 12 14 11.5531 14 11V1C14 0.446875 13.5531 0 13 0H12H3ZM3 12H11V14H3C2.44688 14 2 13.5531 2 13C2 12.4469 2.44688 12 3 12ZM4 4.5C4 4.225 4.225 4 4.5 4H10.5C10.775 4 11 4.225 11 4.5C11 4.775 10.775 5 10.5 5H4.5C4.225 5 4 4.775 4 4.5ZM4.5 6H10.5C10.775 6 11 6.225 11 6.5C11 6.775 10.775 7 10.5 7H4.5C4.225 7 4 6.775 4 6.5C4 6.225 4.225 6 4.5 6Z" 
                fill="currentColor"
            />
        </svg>
    );
}