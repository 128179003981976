import axios from 'axios'

class Http {
    constructor () {

        let config = {
            baseURL: process.env.REACT_APP_URL_API,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, HEAD, OPTIONS"
            },
            timeout: 300000
        };
        this.axios = axios.create(config);
    }

    get(resource, params) {
        return this.axios.get(resource, params);
    }

    head(resource, params) {
        return this.axios.head(resource, params);
    }

    post(resource, params, header) {
        return this.axios.post(resource, params, header);
    }

    postConfig(config) {
        return this.axios(config);
    }

    delete(resource, params, header) {
        return this.axios.delete(resource, params, header);
    }

    put(resource, params, header) {
        return this.axios.put(resource, params, header);
    }

    download(resource, params) {
        return this.axios.get(resource, params);
    }

    configInterceptors = () => {
        this.axios.interceptors.request.use(this.reqConfig);
        this.axios.interceptors.response.use(res => res, this.resErrorHandler);
    };

    reqConfig = (config) => {
        const token = window.localStorage.token;

        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }
        return Promise.resolve(config);
    };

    resHandle = (response) => {
        if (response.data) {
            let data = response.data;
            if (data.erro) throw (data.message + " : " + JSON.stringify(data.result)).toString();
            return data.result
        }
    };

    resErrorHandler = (error) => {
        if (!error.response) {
            throw error;
        }
        return Promise.reject(error);
    }

}

export default new Http();
