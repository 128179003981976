import Http from "../commons/utils/Http";
import Token from '../commons/utils/token';
import { errorHandling } from "../commons/utils/error";

class searchService {

    async autocomplete(body) {
        const data = {
            q: body
        };

        try {
            let response = await Http.post('/api/autocomplete', data);
            return response
        } catch (e) {
            errorHandling(e)
        }
    }

    async getFilters() {
        try {
            let response = await Http.get(`/filters`);
            return response;
        } catch (e) {
            errorHandling(e)
        }
    }

    async sendInformationsLoading(uri, idSearch, timestampSearch, initTimestampSearch) {

        const token = Token.getLocalStorageJWTToken();
        const tokenJson = Token.parseTokenPayload(Token.decodeJWTToken(token));

        const data = {
            "uri": uri,
            "endpoint": "/api",
            "id_session": token,
            "id_user": tokenJson.id,
            "id_search": idSearch,
            "timestamp_search": timestampSearch,
            "timestamp_event": initTimestampSearch,
            "timestamp_loading" : new Date().toISOString().replace('Z', '000')
        }

        try {
            let response = await Http.post('/api_loading', data);
            return response
        } catch (e) {
            errorHandling(e)
        }
    }
 
    async getSuggestion(query) {
        try {
            let response = await Http.get(`/suggest/${query}`);
            return response;
        } catch (e) {
            errorHandling(e)
        }
    }

    async search(query, page, document_filter, id_search, timestamp_search, filters) {
        const token = Token.getLocalStorageJWTToken();
        const tokenJson = Token.parseTokenPayload(Token.decodeJWTToken(token));

        const headers = {
            "headers": {
                'id-session': token,
                'id-user':  tokenJson.id,
                'id-search': id_search,
                'timestamp-search': timestamp_search,
                'section-filter': '',
                'category-filter': filters.get('categoryFilter'),
                'status-filter': filters.get('statusFilter')
            }
        };
        
        query = encodeURIComponent(query);
        try {
            let response = await Http.get(`/api?query=${query}&document_filter=${document_filter}&time_filter=${filters.get('periodFilter')}&this_page=${page}`, headers);
            return response;
        } catch (e) {
            errorHandling(e)
        }
    }

    async history(user) {        
        const data = {
            id_user: user.id,
            user_name: user.name
        };

        try {
            let response = await Http.post('/api/user/history', data);
            return response
        } catch (e) {
            errorHandling(e)
        }
    }
}

export default new searchService();