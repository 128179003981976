import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import './statistics.css';

export function Statistics() {

    const navigate = useNavigate(); 

    return (
        <div className="justify-content">
            <Result
                status="404"
                title="Ops! Ainda estamos trabalhando"
                subTitle="Avisaremos quando ficar pronta ;)"
                extra={<a  onClick = {() => navigate('/')}>Retornar para a página inicial</a>}
            />
        </div>
    )
}