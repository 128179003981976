import HttpStatus from 'http-status-codes'
import Message from '../utils/message'

export function errorHandling(e) {

    if (e.response) {
        if (e.response.status === HttpStatus.UNAUTHORIZED || e.response.status === HttpStatus.FORBIDDEN) {
            return Message.showError("Não autorizado", e);
        }
    }

    if (!e.response) {
        Message.showError("Tivemos um problema no servidor", e);
    } else if (e.response.status !== HttpStatus.OK && e.response.status !== HttpStatus.INTERNAL_SERVER_ERROR) {
        Message.showError(e.response.data.response.message ? e.response.data.response.message : 'Erro', e);
    } else if (e.response.status === HttpStatus.INTERNAL_SERVER_ERROR || e.response.status === HttpStatus.NOT_FOUND) {
        Message.showError(e.response.data.error ? e.response.data.message : 'Erro', e);
    }
}
