import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import 'App.css';
import '../filter-component/filter.css';
import { useState } from 'react';
import { Dropdown, Space, Menu } from 'antd';
import { DownOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import IconAll from "../../icons/icon-all.svg";
import IconHelpCenter from "../../icons/help-center.svg";
import IconOs from "../../icons/os.svg";
import IconTicket from "../../icons/tickets.svg";
import IconTecNotes from "../../icons/tec-notes.svg";
import IconArrowDown from "../../icons/arrow-down.svg";
import ServiceSearch from '../../../services/search';
import { useParams, useSearchParams } from 'react-router-dom';

interface IFilter {
    handleDocumentFilter: (arg: string) => void,
    handleApplyFilters: (periodFilter: any, categoryFilter: any, statusFilter: any) => void,
}

export const Filter: React.FC<IFilter> = ({handleDocumentFilter, handleApplyFilters}) => {

    const { typeDoc } = useParams();
    const [ searchParams ] = useSearchParams();

    const [ filterSelected, setFilterSelect ] = useState('all');
    const [ anotherFilters, setAnotherFilters ] = useState<any>({"periodFilter": "last_ever", "categoryFilter": "", "statusFilter": ""});

    const [ showDropdown, setShowDropdown ] = useState(false);

    const [ optionsPeriodFilter, setOptionsPeriodFilter ] = useState<any>([]);
    const [ optionsCategoryFilter, setOptionsCategoryFilter ] = useState<any>([]);
    const [ optionsStatusFilter, setOptionsStatusFilter ] = useState<any>([]);

    useEffect( () => {
        getFilters();
        updateFilterSelect(typeDoc !== undefined ? typeDoc : 'all');

        if (searchParams.get('periodFilter') !== 'last_ever' 
        || searchParams.get('categoryFilter') !== '' 
        || searchParams.get('statusFilter') !== '') {
            setShowDropdown(true);
            setAnotherFilters({"periodFilter": searchParams.get('periodFilter'), "categoryFilter": searchParams.get('categoryFilter'), "statusFilter": searchParams.get('statusFilter')});
            handleApplyFilters(searchParams.get('periodFilter'), searchParams.get('categoryFilter'), searchParams.get('statusFilter'));
        }
    }, []);

    const getFilters = async () => {
        await ServiceSearch.getFilters().then( response => {
            let filters = response?.data.filters;
            setOptionsPeriodFilter(Object.keys(filters.time_filter).map(key => ({'key': filters.time_filter[key], 'value': key})));
            setOptionsCategoryFilter(Object.keys(filters.category_filter).map(key => (key === 'Toda a Central de Ajuda' ? {'key': '', 'value': key} : {'key': key, 'value': key})));
            setOptionsStatusFilter(Object.keys(filters.status_filter).map(key => ({'key': filters.status_filter[key], 'value': key })));
        })
    }
    
    const updateFilterSelect = (typeDoc: string):void => {
        setFilterSelect(typeDoc);
        handleDocumentFilter(typeDoc);
    }

    const clearFilter = (event:any): void => {
        setShowDropdown(false);
        setAnotherFilters({"periodFilter": "last_ever", "categoryFilter": "", "statusFilter": ""});
        handleApplyFilters('last_ever', "", "");
    }

    const updateFilters = (value: string, typeFilter: string):void => {

        if (typeFilter === 'periodFilter') {
            setAnotherFilters({...anotherFilters, periodFilter: value });
            handleApplyFilters(value, anotherFilters.categoryFilter, anotherFilters.statusFilter);
        } else if (typeFilter === 'categoryFilter') {
            setAnotherFilters({...anotherFilters, categoryFilter: value });
            handleApplyFilters(anotherFilters.periodFilter, value, anotherFilters.statusFilter);
        } else if (typeFilter === 'statusFilter') {
            setAnotherFilters({...anotherFilters, statusFilter: value });
            handleApplyFilters(anotherFilters.periodFilter, anotherFilters.categoryFilter, value);
        }
        
    }

    const menu = (items:any, typeFilter: string) => (
        <Menu
            items={ items.map( (item:any) => ({
                key: item.key,
                label: (
                    <div onClick={ (event) => updateFilters(item.key, typeFilter)}>
                        { (anotherFilters[typeFilter] === item.key) && <CheckOutlined /> }
                        <span
                            className={(anotherFilters[typeFilter] === item.key ? 'option-selected' : '') + ' font-size-filter' } 
                        >
                            { item.value }
                        </span>
                    </div>
                    ),
            }))
        }
        />
    );

    const filteredSelectionPeriod = optionsPeriodFilter.filter((element: { key: string }) => {
        return element.key === anotherFilters.periodFilter;
    });

    const filteredSelectionCategory = optionsCategoryFilter.filter((element: { key: string }) => {
        return element.key === anotherFilters.categoryFilter;
    });

    const filteredSelectionStatus = optionsStatusFilter.filter((element: { key: string }) => {
        return element.key === anotherFilters.statusFilter;
    });

    return (
        <div id='section-filters'>
            <div id='filters'> 
                <div id='content-filter'>
                    <div id='itens-filter'>
                        <div 
                            className={'item-filter ' + (filterSelected === 'all' ? 'filter-selected' : '') }
                            onClick={ () => updateFilterSelect('all')}>
                            <img src={IconAll} />
                            <span>Todos</span>
                        </div>

                        <div 
                            className={'item-filter ' + (filterSelected === 'ART' ? 'filter-selected' : '') }
                            onClick={ () => updateFilterSelect('ART')}>
                            <img src={IconHelpCenter} />
                            <span>Central de Ajuda</span>
                        </div>

                        <div 
                            className={'item-filter ' + (filterSelected === 'OS' ? 'filter-selected' : '') }
                            onClick={ () => updateFilterSelect('OS')}>
                            <img src={IconOs} />
                            <span>Ordens de serviço</span>
                        </div>

                        <div 
                            className={'item-filter ' + (filterSelected === 'TCK' ? 'filter-selected' : '') }
                            onClick={ () => updateFilterSelect('TCK')}>
                            <img src={IconTicket} />
                            <span>Tickets</span>
                        </div>

                        <div 
                            className={'item-filter ' + (filterSelected === 'NTC' ? 'filter-selected' : '') }
                            onClick={ () => updateFilterSelect('NTC')}>
                            <img src={IconTecNotes} />
                            <span>Notas Técnicas</span>
                        </div>

                        <div id='filter-dropdown' className='item-filter filter-dropdown' onClick={() => {setShowDropdown(!showDropdown)}}>
                            <span>Filtrar resultados</span>
                            <img src={IconArrowDown} />
                        </div>
                    </div>
                </div>
            </div>

            <div id='itens-filter-dropdown'  
                className={(showDropdown ? 'show-dropdown' : 'dont-show-dropdown') }>

                    <div id='content-itens-filter-dropdown'>
                        <Dropdown overlay={menu(optionsPeriodFilter, "periodFilter")} 
                            className={'item-filter-dropdown filter-dropdown ' + (anotherFilters.periodFilter !== 'last_ever' ? 'subfilter-dropdown-selected' : '') }
                        >
                            <a onClick={e => e.preventDefault()}>
                                <Space>
                                    {anotherFilters.periodFilter !== 'last_ever' ? filteredSelectionPeriod.map((e: { value: any; }) => {return (e.value)}) : 'Período' }
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>

                        <Dropdown overlay={menu(optionsCategoryFilter, "categoryFilter")} 
                            className={'item-filter-dropdown filter-dropdown ' + (anotherFilters.categoryFilter !== '' ? 'subfilter-dropdown-selected' : '') }
                        >
                            <a onClick={e => e.preventDefault()}>
                                <Space>
                                    {anotherFilters.categoryFilter !== '' ? filteredSelectionCategory.map((e: { value: any; }) => {return (e.value)}) : 'Central de Ajuda' }
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>

                        <Dropdown overlay={menu(optionsStatusFilter, "statusFilter")} 
                            className={'item-filter-dropdown filter-dropdown ' + (anotherFilters.statusFilter !== '' ? 'subfilter-dropdown-selected' : '') }
                        >
                            <a onClick={e => e.preventDefault()}>
                                <Space>
                                    {anotherFilters.statusFilter !== '' ? filteredSelectionStatus.map((e: { value: any; }) => {return (e.value)}) : 'Status OS/Tickets' }
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>

                        {
                            searchParams.get('periodFilter') !== 'last_ever' 
                            || searchParams.get('categoryFilter') !== '' 
                            || searchParams.get('statusFilter') !== '' ?
                            <div id="clear-filters" onClick={ event => clearFilter(event)}>
                                <CloseOutlined />
                                <span>Limpar Filtros</span>
                            </div>
                        :
                            ''
                        }
                    </div>
            </div>
        </div>
    )
}
