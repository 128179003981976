interface Props {
    className?: string;
}

export default function IconOS(props: Props) {
    return (
        <svg className={props.className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M11.7485 0.664027C12.6333 -0.221342 14.0692 -0.221342 14.954 0.664027L15.335 1.04567C16.2199 1.93114 16.2199 3.36671 15.335 4.25154L9.12544 10.4643C8.85149 10.7351 8.51456 10.9335 8.14299 11.0405L4.99412 11.9411C4.72961 12.0167 4.44621 11.9411 4.25098 11.7207C4.0589 11.5538 3.98333 11.2704 4.0589 11.0059L4.95948 7.85701C5.06654 7.48544 5.26492 7.14851 5.53573 6.87456L11.7485 0.664027ZM13.8582 1.73276C13.5906 1.43771 13.1119 1.43771 12.8159 1.73276L11.8996 2.64876L13.3512 4.1004L14.2676 3.15573C14.5636 2.88808 14.5636 2.40945 14.2676 2.1144L13.8582 1.73276ZM6.41426 8.27266L5.88525 10.1147L7.72734 9.58574C7.8533 9.5511 7.96351 9.48497 8.05483 9.39366L12.2838 5.16787L10.8321 3.71623L6.60634 7.94517C6.51503 8.03649 6.4489 8.1467 6.41426 8.27266ZM6.29775 1.89303C6.71655 1.89303 7.05348 2.23154 7.05348 2.64876C7.05348 3.06756 6.71655 3.40449 6.29775 3.40449H2.77101C2.07542 3.40449 1.51146 3.96814 1.51146 4.66404V13.229C1.51146 13.9249 2.07542 14.4885 2.77101 14.4885H11.336C12.0319 14.4885 12.5955 13.9249 12.5955 13.229V9.70225C12.5955 9.28345 12.9324 8.94652 13.3512 8.94652C13.77 8.94652 14.107 9.28345 14.107 9.70225V13.229C14.107 14.7593 12.8663 16 11.336 16H2.77101C1.24066 16 0 14.7593 0 13.229V4.66404C0 3.13369 1.24066 1.89303 2.77101 1.89303H6.29775Z" 
                fill="currentColor"
            />
        </svg>
    );
}
