import HttpService from '../commons/utils/Http';
import Token from '../commons/utils/token';
import { errorHandling } from "../commons/utils/error";

class RegisterUserAccess {
    postDataAccess(){
        const jwtToken = Token.getLocalStorageJWTToken();
        const decodedToken = Token.decodeJWTToken(jwtToken);
        const tokenPayload = Token.parseTokenPayload(decodedToken);

        const userData = { 
            id_user: tokenPayload.id,
            user_name: tokenPayload.user_name,
        }

        try{
            let response = HttpService.post('/api/user/access', userData)
            return response;

        } catch(e) {
            errorHandling(e)
        }
    } 
}

export default new RegisterUserAccess();
