import { useState, useEffect } from 'react';
import './opt-in.css';
import { Button, Modal } from 'antd';
import OptInService from 'services/optIn';
import { BsShieldCheck } from 'react-icons/bs';

export function OptIn({ showModal, setShowModal }: { showModal: boolean, setShowModal: (showModal: boolean) => void }) {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [cancelConfirmationModal, setCancelConfirmationModal] = useState(false);

    const handleOk = () => {
        setOptIn(true);
        setConfirmLoading(true);
    };

    const handleCancelConfirmation = () => {
        setCancelConfirmationModal(true);
        setShowModal(false);
    }


    const handleBackToTerms = () => {
        setCancelConfirmationModal(false);
        setShowModal(true);
    }

    const handleCancel = () => {
        setOptIn(false);
    };

    const setOptIn = (accepted: boolean) => {
        OptInService.sendOptIn(accepted).then(res => {
            setCancelConfirmationModal(false);
            setShowModal(false)
            setConfirmLoading(false);

            if(accepted) {
                window.location.reload();
            }
        });
    }

    const titleModal = (
        <div>
            <span className='icon-title-modal'>
                <BsShieldCheck size='25' color='green' />
            </span>
            Termos de Uso e adequação LGPD
        </div>
    );


    return (
        <>
            <Modal
                title={titleModal}
                open={showModal}
                confirmLoading={confirmLoading}
                onOk={handleOk}
                onCancel={handleCancelConfirmation}
                okText="ACEITAR"
                cancelText="NÃO ACEITAR"
                destroyOnClose={true}
            >
                <p>
                    O colaborador está ciente de que a ferramenta Busca Sankhya apresenta informações de ordens de serviço e tickets,
                    sendo que essas podem conter informações internas sensíveis, inseridas por nossos analistas durante a execução de
                    atendimentos relacionados ao suporte e implantação da solução Sankhya nos nossos clientes.
                </p>
                <br />
                <p>
                    Portanto, o colaborador concorda que essas informações não podem ser compartilhadas com pessoas ou meios fora do grupo Sankhya,
                    em nenhuma hipótese.
                </p>
                <br />
                <p>
                    O colaborador também se compromete ao bom uso, respeitando as disposições da Lei Geral de Proteção de Dados Pessoais (LGPD - 13.709/2018).
                </p>
            </Modal>
            <Modal
                title={titleModal}
                open={cancelConfirmationModal}
                onOk={handleBackToTerms}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText="VOLTAR E LER NOVAMENTE"
                cancelText="NÃO ACEITAR"
            >
                <h3>ATENÇÃO</h3>
                <p>
                    Você escolheu <strong>NÃO</strong> aceitar os termos de uso. É
                    importante ressaltar que, neste caso, as informações
                    de ordens de serviço e tickets <strong>NÃO</strong> estarão
                    disponíveis em sua pesquisa.

                </p>
                <br />
                <p>
                    Se ficou alguma dúvida você pode voltar e ler
                    novamente. Porém, se realmente não concorda com
                    os termos, clique na opção respectiva.
                </p>
            </Modal>

        </>
    );
}