import { Home, PageResultsSearch, Card } from './commons/components';
import { ApplicationContextProvider } from './commons/context/appContext';
import { Routes, Route } from 'react-router-dom';
import { SankhyaHub } from './commons/components/sankhyahub-component';
import { Statistics } from './commons/components/statistics-component/statistics';

export default function App() {

  return (
    <div className='App'>
      <ApplicationContextProvider>
        <SankhyaHub/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/results/:typeDoc/:query/:currentPage" element={<PageResultsSearch />} />
          <Route path="/docs/:typeDoc/:idCard" element={<Card />} />
          <Route path="/statistics" element={<Statistics />} />
        </Routes>
      </ApplicationContextProvider>
    </div>
  );
}
