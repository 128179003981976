import * as React from "react";

function SvgCheckMark(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18.4 18.4" {...props}>
      <path
        d="M13.178 6.344a.7.7 0 010 .994l-4.717 4.718a.7.7 0 01-.994 0L5.222 9.81a.703.703 0 01.994-.994l1.748 1.748 4.22-4.22a.7.7 0 01.994 0zM18.2 9.2a9 9 0 11-9-9 9 9 0 019 9zm-1.406 0A7.594 7.594 0 109.2 16.794 7.59 7.59 0 0016.794 9.2zm0 0"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default SvgCheckMark;

