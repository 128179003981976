import * as React from "react";

function SvgError(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18.4 18.4" {...props}>
      <g data-name="Grupo 464" fill="#fff">
        <path
          d="M18.2 9.2a9 9 0 11-9-9 9 9 0 019 9zm-1.406 0A7.594 7.594 0 109.2 16.794 7.59 7.59 0 0016.794 9.2zm0 0"
          stroke="#fff"
          strokeWidth={0.4}
        />
        <path
          d="M10.559 11.918L9.2 10.559l-1.359 1.359a.961.961 0 01-1.359-1.36L7.84 9.2 6.481 7.841a.963.963 0 010-1.36.961.961 0 011.359 0L9.2 7.84l1.359-1.359a.961.961 0 011.359 1.36L10.559 9.2l1.359 1.358a.961.961 0 11-1.359 1.36z"
          data-name="Uni\xE3o 21"
        />
      </g>
    </svg>
  );
}

export default SvgError;

