import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

interface IApplicationContextType {
    search: any, 
    setSearch: Dispatch<SetStateAction<any>>
}

const ApplicationContext = createContext(
  {} as IApplicationContextType,
);

const { Provider } = ApplicationContext;

export const ApplicationContextProvider: React.FC<any> = ({ children }) => {

  const [search, setSearch] = useState<IApplicationContextType>();

  return (
    <Provider
      value={{
        search,
        setSearch
      }}>
      {children}
    </Provider>
  );
};

export const useApplicationContext = () => {
  if (!useApplicationContext) {
    throw new Error(
      "Can't use this hook outside a useApplicationContextProvider!",
    );
  }

  return useContext(ApplicationContext);
};
