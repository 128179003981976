import { AutoComplete, Button, Divider, Form, Input, Layout, SelectProps, Space } from 'antd';
import { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import './autocomplete.css';
import { FieldTimeOutlined, FolderOpenOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import ServiceSearch from '../../../services/search';
import Token from '../../utils/token';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';


export function AutoCompleteInput({page, query, handleResetQuery}: { page: string, query:any, handleResetQuery: () => void }) {
    
    const navigate = useNavigate();  
    const { typeDoc } = useParams();
    const [ searchParams ] = useSearchParams();
    const [ flagHistoric, setFlagHistoric ] = useState(false);

    const [options, setOptions] = useState<SelectProps<object>['options']>([]);
    const [value, setValue] = useState("");
    
    const [form] = Form.useForm();
    const [sizeHeightDropdown, setSizeHeightDropdown] = useState(0);
    const [open, setOpen] = useState(true);

    const maxLength = 101;
    const firstPage = '1';
    const pageHome = 'home';
    const pageVisResult = 'visResult';

    useEffect(() => {
      if (window.screen.height <= 700) {
        setSizeHeightDropdown(window.screen.height/3);
      } else if (window.screen.height <= 900) {
        setSizeHeightDropdown(300);
      } else {
        setSizeHeightDropdown(400);
      }  

      const selectElement = document.querySelector('.ant-select-selector');
      if (page === pageHome) {
        setFlagHistoric(false);
        searchHistoric(); 
        selectElement?.classList.add('page-home');
        selectElement?.classList.remove('page-results');
      } else {
        if (flagHistoric && (form.getFieldValue('busca') === undefined || form.getFieldValue('busca') === '')){
          searchHistoric(); 
        } else {
          setFlagHistoric(true);
          setOpen(false);
        }

        selectElement?.classList.remove('page-home');
        selectElement?.classList.add('page-results');
        
        let autocompleteInput = (document.getElementById('autocomplete') as HTMLInputElement);
        autocompleteInput.style.marginLeft = '24px';
        setValue(query);
        form.setFieldValue('busca', query); // Para contornar o cenário do bug da sugestão: clicar na sugestão e aparecer o valor no input
      }

    }, [query]);  // [query]: Para contornar o cenário do bug da sugestão: clicar na sugestão e aparecer o valor no input

    const handleKeyDown = (e: any) => {
      if (e.code === 'Enter') {
        searchClick(true);
      }
    };

    const searchHistoric = async () => {
      if (value === undefined || value === '') {
        const token = Token.getLocalStorageJWTToken();
        const tokenJson = Token.parseTokenPayload(Token.decodeJWTToken(token));
        
        let response = await ServiceSearch.history({ id: tokenJson.id, name: tokenJson.user_name });
        const selectElement = document.querySelector('.ant-select-selector');

        if (response !== undefined && response.data.length > 0) {
          setOpen(true);
          selectElement?.classList.add('has-suggestion');
          setOptions(historicResult(response?.data));
        } else {
          setOpen(false);
          selectElement?.classList.remove('has-suggestion');
        }
      } else {
        setOpen(true);
      }
    };

    const searchClick = async (control:boolean) => {
      try {
        const valueBusca = form.getFieldValue('busca');
        let autocompleteInput = (document.getElementById('autocomplete') as HTMLInputElement).value;

        if (valueBusca === ''){
            form.setFieldValue('busca', autocompleteInput); //  Histórico: cenario selecionar e clicar pesquisar
        } else if (autocompleteInput === '') {
          autocompleteInput = valueBusca; // Histórico: cenario de clique em opções do historico
        }

        await form.validateFields();

        let query = control ? form.getFieldValue('busca') : autocompleteInput; // Para contornar cenario de clique em opções de sugestões
        
        if (query.length > 100) {
          query = query.substring(0, 100);
        }
        query = query.replaceAll('/', ' ');
        query = encodeURIComponent(query);

        setOpen(false);
        const selectElement = document.querySelector('.ant-select-selector');
        selectElement?.classList.remove('has-suggestion');

        handleResetQuery();

        if(page === pageHome || page === pageVisResult) {
          navigate(`/results/all/${query}/${firstPage}?periodFilter=last_ever&categoryFilter=&statusFilter=`);
        } else {
          navigate(`/results/${typeDoc}/${query}/${firstPage}?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`);
        }

      } catch (errorInfo) {}
    };
    
    const handleChange = (value: string) => {
      setValue(value);
    };

    const handleBlur = () => {
      const selectElement = document.querySelector('.ant-select-selector');
      selectElement?.classList.add('border-radius-focus');

      setOpen(false);
    }

    const handleFocus = async () => {
      const selectElement = document.querySelector('.ant-select-selector');
      selectElement?.classList.remove('border-radius-focus');
      
      if ((page === pageHome || flagHistoric) && (form.getFieldValue('busca') === undefined || form.getFieldValue('busca') === '')) {
        searchHistoric();
      } 
      
    }

    const handleSearch = async (value: string) => {
      let response = await ServiceSearch.autocomplete(value);
      
      setOptions(value && response !== undefined && response.data.length !== 0 ? searchResult(response.data) : []);
      
      const selectElement = document.querySelector('.ant-select-selector');
      
      if (value && response !== undefined && response.data.length !== 0) {
        selectElement?.classList.add('has-suggestion');
        setOpen(true);
      } else {
        selectElement?.classList.remove('has-suggestion');
        setOpen(false);
      }
    };
  
    const onSelect = (value: string) => {
      setValue(value);
      searchClick(true);
    };

    return (
      <Form
        name="basic"
        form={form}
        labelCol={{ span: (page === pageHome ? 8 : 12) }} 
        wrapperCol={{ span: 16 }} 
        initialValues={{ remember: true, busca: query }}
        autoComplete="on"
      >
        <Form.Item
            name="busca"
            rules={[{ required: true, message: 'Favor preencher campo de busca!' },
                    {
                      validator: async (_, busca) => {
                        if (busca && busca.length === maxLength) {
                          return Promise.reject(new Error('Limitamos a pesquisa em 100 caracteres. Experimente utilizar palavras chaves.'));
                        }
                      },
                    }]}
            style={{marginBottom: (page === pageVisResult ? '15px' : '0px')}}
          >
            <AutoComplete id='autocomplete'
              dropdownMatchSelectWidth={true}
              dropdownAlign={{
                offset: [0, -1]
              }}
              listHeight={sizeHeightDropdown}
              dropdownStyle={{borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px'}}
              style={{ width: (page === pageHome ? '600px' : '820px') }} 
              options={options}
              value={value}
              onChange={handleChange}
              onSelect={onSelect}
              onSearch={handleSearch}
              autoFocus={true}
              onBlur={handleBlur}
              onFocus={handleFocus}
              backfill={true}
              open={open}
              maxLength={maxLength}
              > 

              {
                page === pageHome ?

                <Input onKeyDown={handleKeyDown}
                  prefix={
                    <SearchOutlined style={{opacity: '0.5', fontSize: '20px', marginLeft: '-20px'}}/>
                  } 
                  suffix={
                    <Button type='primary' className='Search-button-border-radius' onClick={() => searchClick(false)} htmlType="submit">
                      Pesquisar
                    </Button>
                  } 
                />
                
                :

                <Input onKeyDown={handleKeyDown} 
                allowClear={{clearIcon: <CloseOutlined twoToneColor="#FFFFFF" style={{ fontSize: '25px', display: 'block', color:'#D2D3DA', marginRight:'-15px'}}/>}}
                  suffix={
                    <div>
                      <Divider type="vertical" style={{height: '30px', border: '1px solid #bbb'}} />
                      <SearchOutlined type="primary" style={{fontSize: '23px', marginRight: '30px', marginTop: '3px'}} onClick={() => searchClick(false)} />
                    </div>
                  } 
                />

              }
              
            </AutoComplete>
        </Form.Item>
    </Form>
    )
}

const searchResult = (response: any) =>
  new Array(response.length)
    .join('.')
    .split('.')
    .map((_, idx) => {
      const category = `${response[idx].label}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
             <div className="space-align-container">
              <div className="space-align-block">
                <Space align="center">
                  <SearchOutlined style={{opacity: '0.5', marginRight: '10px'}}/>
                  <Layout className='layout-selection'>
                    { response[idx].label }
                    <p style={{opacity: '0.5', marginLeft: '10px', marginBottom: '0px'}}>
                      <FolderOpenOutlined style={{marginRight: '5px'}}/>
                      { response[idx].desc }
                    </p>
                  </Layout>
                </Space>
              </div>
            </div>
          </div>
        ),
      };
    });

  const historicResult = (response: any) =>
    new Array(response.length)
      .join('.')
      .split('.')
      .map((_, idx) => {
        const category = `${response[idx].texto_busca}`;
        return {
          value: category,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
               <div className="space-align-container">
                <div className="space-align-block">
                  <Space align="center">
                    <FieldTimeOutlined style={{opacity: '0.5', marginRight: '10px'}}/>
                    <Layout className='layout-selection'>
                      { response[idx].texto_busca }
                    </Layout>
                  </Space>
                </div>
              </div>
            </div>
          ),
        };
      });