import { Divider, Drawer } from "antd";
import './about-content.css';
export function AboutContent({ openAboutContentSection, setOpenAboutContentSection }: { openAboutContentSection: boolean, setOpenAboutContentSection: (closed: boolean) => void }) {
    return (
        <div className="about-content">
            <Drawer 
                title="Sobre os Conteúdos"
                placement="right"
                open={openAboutContentSection}
                width={'30%'}
                onClose={ () => setOpenAboutContentSection(false)}
            >
                <div className='sections-modal'>
                    <h1>Central de Ajuda</h1>

                    <p>Apresentamos os conteúdos disponíveis no site <a href="http://ajuda.sankhya.com.br" target="_blank">ajuda.sankhya.com.br</a>:</p>
                    <br />
                    <p className='prominent-information-modal'>Dúvidas Frequentes</p>
                    <p>Aqui você encontra as respostas das perguntas mais frequentes feitas aos nossos especialistas.</p>
                    <br />
                    <p className='prominent-information-modal'>Documentação de Telas (Manual)</p>
                    <p>Documentação de Telas do Sistema</p>
                    <br />
                    <p className='prominent-information-modal'>Documentação de Jornadas</p>
                    <p>Documentação de Jornadas do Usuário</p>
                    <br />
                    <p className='prominent-information-modal'>Solução de Problemas</p>
                    <p>Aqui você encontra as orientações para solucionar os problemas mais frequentes.</p>
                    <br />
                    <p className='prominent-information-modal'>Melhores Práticas</p>
                    <p>Melhores práticas utilizadas.</p>
                    <br />
                    <p>Entre outros</p>
                    <br />
                    <p>Atualização das informações</p>
                    <ul>
                        <li>Origem: Zendesk</li>
                        <li>Periodicidade de atualização: A cada 3 horas.</li>
                    </ul>
        
                </div>

                <Divider />

                <div className='sections-modal'>
                    <h1>Ordens de Serviço</h1>

                    <p>São apresentadas as ordens de serviço que:</p>
                    <ul>
                        <li>Possuem ticket do Service Desk relacionado</li>
                        <li>Passaram pelo Teste de Entrada</li>
                        <li>Passaram pelo Documentação</li>
                        <li>Incluindo OS de projeto (FAP), que geralmente trata de evolução do produto.</li>
                    </ul>
                    <br />
                    <p>Observação: Essa categoria está disponível quando seu usuário (Sankhya ID) possui acesso à tela “Consulta de OS” no Sankhya OM de Produção.</p>
                    <br />
                    <p>Atualização das informações</p>
                    <ul>
                        <li>Origem: Sankhya Om Produção.</li>
                        <li>Periodicidade de atualização: A cada 1 hora.</li>
                    </ul>
                </div>

                <Divider />

                <div className='sections-modal'>
                    <h1>Tickets</h1>
                    
                    <p>São apresentados os tickets do sistema utilizado pelo Service Desk.</p>
                    <br />
                    <p>Observação: Essa categoria está disponível quando seu usuário (Sankhya ID) possui acesso à tela “Consulta de OS” no Sankhya OM de Produção.</p>
                    <br />
                    <p>Atualização das informações</p>
                    <ul>
                        <li>Origem: Zendesk</li>
                        <li>Periodicidade de atualização: A cada 1 hora.</li>
                    </ul>
                </div>

                <Divider />

                <div className='sections-modal'>
                    <h1>Notas ténicas</h1>

                    <p>São notas técnicas do eSocial e da NF-e.</p>
                    <br />
                    <p>Atualização das informações.</p>
                    <ul>
                        <li>Origem: </li>
                        <ul>
                            <li className='subitens'>NF-e: Notas técnicas vigentes do site <a href="http://www.nfe.fazenda.gov.br" target="_blank">www.nfe.fazenda.gov.br</a>, sessão Página Principal {'>'} Documentos {'>'} Notas Técnicas.</li>
                            <li className='subitens'>eSocial: Notas técnicas do site <a href="http://www.gov.br" target="_blank">www.gov.br</a>, sessão NOTAS TÉCNICAS V. S-1.0. </li>
                        </ul>
                        <li>Periodicidade de atualização: A cada 3 dias.</li>
                    </ul>                           
                </div>
            </Drawer>
        </div>
    );
}