import * as React from "react";

function SvgInfo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18.4 18.4" {...props}>
      <defs>
        <style>{".info_svg__prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="info_svg__prefix__Grupo_560"
        data-name="Grupo 560"
        transform="translate(-384.8 -685.8)"
      >
        <path
          id="info_svg__prefix__check-mark"
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.4}
          d="M403 695a9 9 0 11-9-9 9 9 0 019 9zm-1.406 0a7.594 7.594 0 10-7.594 7.594 7.59 7.59 0 007.594-7.594zm0 0"
        />
        <rect
          id="info_svg__prefix__Ret\xE2ngulo_396"
          width={2}
          height={5}
          className="info_svg__prefix__cls-2"
          data-name="Ret\xE2ngulo 396"
          rx={1}
          transform="translate(393 691)"
        />
        <circle
          id="info_svg__prefix__Elipse_24"
          cx={1}
          cy={1}
          r={1}
          className="info_svg__prefix__cls-2"
          data-name="Elipse 24"
          transform="translate(393 697)"
        />
      </g>
    </svg>
  );
}

export default SvgInfo;

