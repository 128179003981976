import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import './tec-notes.css';
import *  as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface ITecNotes {
    updated_at: string,
    section_name: string,
    related_docs: string,
    title: string,
    url_for: string
}

export function TecNotes() {
    const { idCard } = useParams();
    const hasPDFLoaded = useRef(false);
    const DOCUMENT_URL = `${process.env.REACT_APP_URL_API}/api/docs/documents/${idCard}`;

    useEffect(() => {
        if (hasPDFLoaded.current) return;
        hasPDFLoaded.current = true;
        renderPDF();
    }, []);

    async function renderPDF() {
        const pdfContainer = document.querySelector('.pdf_container') as HTMLDivElement;
        pdfContainer.innerHTML = '';
        const pdf = await pdfjs.getDocument(`${DOCUMENT_URL}`).promise;
        for (let i = 1; i <= pdf._pdfInfo.numPages; i++) {
            pdf.getPage(i).then((page) => {
                const pageContainer = document.createElement('div');
                let viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement('canvas');
                const textLayer = document.createElement('div');
                const canvasContext = canvas.getContext('2d');
                const newScale = 820 / viewport.width;
                viewport = page.getViewport({ scale: newScale });
                const renderContext = { canvasContext, viewport } as any;
                pageContainer.classList.add('page_container');
                canvas.classList.add('pdf_render_layer');
                textLayer.classList.add('pdf_text_layer');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                page.render(renderContext);

                page.getTextContent().then(textContent => {

                    pdfjs.renderTextLayer({
                        textContent: textContent,
                        container: textLayer,
                        viewport: viewport,
                        textDivs: []
                    });
                });
                pageContainer.appendChild(canvas);
                pageContainer.appendChild(textLayer);
                pdfContainer.appendChild(pageContainer);
            });
        }
    }

    return (

        <div className='pdf_container'>
        </div>
    );
}