import 'antd/dist/antd.min.css';
import 'App.css';
import './page-results-search.css';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SearchResultItemCard } from '../search-result-item-card-component';
import { AutoCompleteInput } from '../autocomplete-component';
import { Filter } from '../filter-component';
import ServiceSearch from '../../../services/search';
import LineLogoSankhya from "../../icons/line-logo-sankhya2.png";
import { Pagination, Tooltip  } from 'antd';
import { Footer } from '../footer-component';
import { OptIn } from '../opt-in-component';

interface IResultSearch  {
    descricao: string[],
    from_search: [],
    inverse_score: number,
    n_cliques: number,
    nome_parc: string,
    section_id: string,
    section_name: string,
    titulo: string,
    type_doc: string,
    updated_at: string,
    url: string,
    category_name: string,
    doc_position: number
}

export function PageResultsSearch() {    
    let resultCondionatl;

    const navigate = useNavigate();
    const { query, currentPage, typeDoc } = useParams();
    const [ searchParams ] = useSearchParams();
    const location = useLocation();
    const prevScrollState = Number.parseInt(sessionStorage.getItem('scrollState') || '0');
    const [ idSearch, setIdSearch ] = useState("");
    const [ timestampSearch, setTimestampSearch ] = useState("");
    
    const [ documentFilter, setDocumentFilter ] = useState("all");
    
    const [ results, setResults ] = useState([] as Array<IResultSearch>);

    const [ totalResults, setTotalResults ] = useState(0);
    const [ totalDisplayedResults, setTotalDisplayedResults] = useState(totalResults);
    const [ timeSearch, setTimeSearch ] = useState('');

    const [ suggestions, setSuggestions ] = useState('');

    const [ navComplete, setNavComplete ] = useState(true);

    const [ grantedAccess, setGrantedAccess ] = useState(true);

    const [ acceptedUse, setAcceptedUse ] = useState(true);

    const [openModal, setShowModal] = useState(false);

    const firstPage = '1';

    useEffect(() => {
        const handleScroll = (event:any) => {
        
            if(window.scrollY === 0) {
                setNavComplete(true);
            } else {
                setNavComplete(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);

        document.title = typeof(query) === 'string' ? query : 'Busca Sankhya';
        getSuggestions();

        const startTime = new Date().toISOString();

        setDocumentFilter(typeDoc !== undefined ? typeDoc : 'all');
        
        loadingResults().then((response) => {
            window.scrollTo(0, prevScrollState);
            sessionStorage.removeItem('scrollState');

            if(parseInt(currentPage!) > response?.data.last_page) {
                navigate(`/results/${typeDoc}/${query}/${response?.data.last_page}?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`);
            }
            ServiceSearch.sendInformationsLoading(`/results/${typeDoc}/${query}/${response?.data.last_page}?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`,
                response?.data.id_search,
                response?.data.timestamp_search,
                startTime   
            );
        });     
    }, [query, documentFilter, currentPage, searchParams]);

    const getSuggestions = async () => {
        try {
            return await ServiceSearch.getSuggestion(query).then( response => {
                setSuggestions(response?.data.replaceAll('<em>', '<mark>').replaceAll('</em>', '</mark>'));
            })
        } catch (errorInfo) {}
    }

    const loadingResults = async () => {
        try {
            isLoading(true);
            return await ServiceSearch.search(query, currentPage, typeDoc, idSearch, timestampSearch, searchParams).then(response => {
                setTotalResults(response?.data.total_results === undefined ? 0 : response?.data.total_results);
                setTimeSearch(response?.data.time_results);
                setTimestampSearch(response?.data.timestamp_search);
                setIdSearch(response?.data.id_search);
                setResults(response?.data.results === undefined ? [] : response?.data.results);
                setGrantedAccess(response?.data.granted_os_tck);
                setAcceptedUse(response?.data.accepted_use);

                if (response?.data.total_results > 9990) {
                    setTotalDisplayedResults(9990);
                } else {
                    setTotalDisplayedResults(response?.data.total_results);
                }
                isLoading(false);
                return response; 
            });
            
        } catch (errorInfo) {}
    };

    const buildQuery = (query: any):string => {
        let valueQuery = query;
        valueQuery = valueQuery?.replaceAll('/', ' ');
        valueQuery = encodeURIComponent(valueQuery !== undefined ? valueQuery : '');
        return valueQuery;
    }

    const handleDocumentFilter = (typeDoc: string):void => {     
        navigate(`/results/${typeDoc}/${buildQuery(query)}/${firstPage}?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`);
        setDocumentFilter(typeDoc);
    }

    const handleApplyFilters = (periodFilter: string, categoryFilter: string, statusFilter: string):void => {
        navigate(`/results/${typeDoc}/${buildQuery(query)}/${firstPage}?periodFilter=${periodFilter}&categoryFilter=${categoryFilter}&statusFilter=${statusFilter}`);
    }

    const handleResetQuery = ():void => {
        setTimestampSearch("");
        setIdSearch("");
    }

    const handlePagination = (pageNum: number) => {
        navigate(`/results/${typeDoc}/${buildQuery(query)}/${pageNum}?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`);
    }

    const redoSearchWithCorrectTerm = () => {
        const query = encodeURIComponent(suggestions.replaceAll('<mark>','').replaceAll('</mark>','').replaceAll('/', ' '));
        navigate(`/results/${typeDoc}/${buildQuery(query)}/${firstPage}/?periodFilter=${searchParams.get('periodFilter')}&categoryFilter=${searchParams.get('categoryFilter')}&statusFilter=${searchParams.get('statusFilter')}`);
    }

    const isLoading = (isLoading: boolean) => {
        try {
            const loadingDiv = document.querySelector('#loading') as HTMLElement;
            const contentResultsScreen = document.querySelector('#content-results-screen') as HTMLElement;
            if (isLoading) {
                contentResultsScreen.style.display = 'none';
                loadingDiv.style.display = 'block';
            } else {
                contentResultsScreen.style.display = 'flex';
                loadingDiv.style.display = 'none';
            }
        } catch (errorInfo) { }
    }

    const docConditional = (documentFilter === 'OS' || documentFilter === 'TCK');

    if (docConditional && grantedAccess === false) {

        resultCondionatl = 
            <div className='content-access'>
                <span className='msg-alert'>Para visualizar esse conteúdo é necessário que tenha permissão à tela "Consulta de OS" no Sankhya-OM de produção.</span>
                <div className='access-denyied'/>
            </div>;
    } else if (docConditional && acceptedUse === false) {
        resultCondionatl =             
            <div className='content-access'>
                <OptIn showModal={openModal} setShowModal={(e) => setShowModal(e)}/>
                <span className='msg-alert'>
                    <p>Conteúdo não pode ser acessado, pois não obtivemos seu aceite nos termos de uso. </p>
                    <p>
                        <a onClick={() => setShowModal(true)}>Clique aqui</a> para ler e aceitar. 
                    </p>
                </span>
                <div className='access-denyied'/>
            </div>;
    } else {
        if (results !== undefined && results.length === 0) {
            resultCondionatl = <h3>Sua pesquisa não encontrou nenhum resultado correspondente. Tente refazer sua pesquisa utilizando palavras-chave.</h3>;
        } else {
            resultCondionatl = results.map((item:IResultSearch, index:number) => (
                < SearchResultItemCard 
                    typeDoc={item.type_doc} 
                    sectionName={item.section_name} 
                    partnerName={item.nome_parc} 
                    date={item.updated_at} 
                    description={item.descricao}
                    url={item.url} 
                    title={item.titulo}
                    key={index}
                    idSearch={idSearch}
                    docPosition={item.doc_position}
                    totalResults={totalResults}
                    timestampSearch={timestampSearch}
                    categoryName={item.category_name}/>
            ));
        }
    }

    return (
        <div id='results-screen' >  
            <div className='img-background'>      
                <div id='search-section' className={navComplete ? '': 'fixed-nav'}>
                    {navComplete ? '': <img style={{width:'150px'}} src={LineLogoSankhya} />}
                    <AutoCompleteInput page='results' query={query} handleResetQuery={handleResetQuery} />
                </div>

                <Filter handleDocumentFilter={handleDocumentFilter} handleApplyFilters={handleApplyFilters} />
            </div> 
            <div id='loading' />
            <div id='content-results-screen' >
                
                <div id='informations-results-screen'>
                    <span id='total-results-informations-results-screen'>{totalResults.toLocaleString()} resultados em {timeSearch} segundos</span>
                    
                    {
                        (suggestions !== '' && suggestions !== query) &&
                        <div id='search-suggestions'>
                            <span id='span-search-suggestions'>Sugestões de pesquisa:</span>
                            <div id='options-search-suggestions'>
                                <Tooltip title={suggestions.replaceAll('<mark>','').replaceAll('</mark>','')}>
                                    <span 
                                        className='option-search-suggestions' 
                                        onClick={ e => redoSearchWithCorrectTerm()}
                                        dangerouslySetInnerHTML={{ __html: suggestions.slice(0,79) }}/>
                                </Tooltip>
                        </div>
                    </div>
                    }
                    
                </div>

                <div className='flex-container'>
                    <div id='cards-results-screen'>
                        {resultCondionatl}
                    </div>
                </div>
                
                {

                totalResults > 0 &&
                <span id="pageInfo" 
                      style={ totalDisplayedResults <= 10 ? {marginBottom: '50px'} : { marginBottom: '20px'}}>
                        
                        Exibindo {(parseInt(currentPage!) - 1) * 10 + 1}-{ (parseInt(currentPage!)*10 >= totalDisplayedResults ? totalDisplayedResults : parseInt(currentPage!)*10)} de {totalDisplayedResults.toLocaleString()} resultados em {Math.ceil(totalDisplayedResults / 10)} {totalDisplayedResults / 10 > 1 ? 'páginas' : 'página'}
                        
                </span>
                
                }
                <Pagination
                    current={parseInt(currentPage!) || 1}
                    total={totalDisplayedResults}
                    pageSize={10}
                    onChange={handlePagination}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                />
            </div>
            <Footer page='results'/>
        </div>
        
    )
}