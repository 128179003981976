import { useEffect } from 'react';
import './help-center.css'

export function HelpCenter(props: any) {

    return (
        <>
            {
                'body' in props && props.body.length > 0 &&
                <div dangerouslySetInnerHTML={{ __html: `${props.body}` }} className="help-center">
                </div>
            }
        </>
    );

}