import './footer.css';
import { Divider, Drawer  } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AboutContent } from '../about-content-component';

export function Footer(props: any) {

    const [ openAboutContentSection, setOpenAboutContentSection ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const footer = document.querySelector('.footer');
        const contentBox = document.querySelector('.footer .content-box');

        footer?.classList.add(`${props.page}`);
        contentBox?.classList.add(`${props.page}`);

    }, []);

    return (  
        <>
            <AboutContent openAboutContentSection={openAboutContentSection} setOpenAboutContentSection={(e) => setOpenAboutContentSection(e)}/>
            <div className='footer'>
                <div className='content-box'>
                    <a className='footer-link' id='sankhyaTrademark'>© {new Date().getFullYear()} Sankhya</a>
                    <a className='footer-link' onClick = {() => setOpenAboutContentSection(true)}>Sobre os conteúdos</a>
                    <a className='footer-link' onClick = {() => navigate(`/statistics`)}>Estatísticas</a>
                </div>
            </div>
        </>  
        
    )
}