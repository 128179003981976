import { Descriptions } from "antd";
import './tickets.css';
import { marked } from 'marked';
import { useEffect } from "react";

export function Tickets(props: any) {

    useEffect(() => {
        const headTitle = document.querySelector('.ant-descriptions-title') as HTMLElement;
        const contentSpan = document.querySelectorAll('.ant-descriptions-item-content') as NodeListOf<HTMLElement>;;

        if (headTitle) {
            headTitle.innerHTML = `${marked.parse(props.description)}`;
        }
        if ('comments' in props) {
            contentSpan.forEach((item: any, index: any) => {
                item.innerHTML = marked.parse(props.comments[index].Content || '');
            })
        }
    });

    return (
        <div className="ticket">
            {
                "comments" in props &&
                <Descriptions layout="horizontal" title column={1} bordered>
                    {
                        props.comments.map((item: any, index: number) => {
                            const labelAux = `Item: ${item.num_comment}\n${item.Data_Criacao}\nInício: ${item.Horario_Criacao}\n${item.user_name}`;
                            return <Descriptions.Item key={`comment-${index}`} label={`${labelAux}`}>{''}</Descriptions.Item>;
                        })
                    }
                </Descriptions>
            }
        </div>

    );
}