import Http from "../commons/utils/Http";
import token from "../commons/utils/token";

class DocumentViewService {
    async getDocument(url, document_filter, query, idSearch, timestampSearch, docPosition, totalResults) {

        const typeDocByName = {
            'notes' : 'NTC',
            'articles' : 'ART',
            'os' : 'OS',
            'tickets' : 'TCK',
        } 

        const idSession = token.getLocalStorageJWTToken();
        const userInfo = token.parseTokenPayload(token.decodeJWTToken(token.getLocalStorageJWTToken()));

        const header = {
            headers: {
                'id-session':idSession,
                'id-user': userInfo.user_name,
                'id-search': idSearch,
                'timestamp-search': timestampSearch,
                'doc-position': docPosition,
                'total-results': totalResults
            }
        }
        
        return (
            await Http.get(`/url?url=${encodeURIComponent(url)}&document_filter=${typeDocByName[document_filter]}&query=${query}`, header)   
            .then(response => {
                if(response.status === 200){
                    return response.data;
                }
            })
            .catch(error => {
                console.error(error);
                return {error: error};
            })
        );
    }

    async getAttachments(url, document_filter, idSearch, timestampSearch){

        const typeDocByName = {
            'notes' : 'NTC',
            'articles' : 'ART',
            'os' : 'OS',
            'tickets' : 'TCK',
        } 

        const idSession = token.getLocalStorageJWTToken();
        const userInfo = token.parseTokenPayload(token.decodeJWTToken(token.getLocalStorageJWTToken()));

        const header = {
            headers: {
                'id-session':idSession,
                'id-user': userInfo.user_name,
                'id-search': idSearch,
                'timestamp-search': timestampSearch
            }
        }

        return (
            await Http.get(`/api_attachments?document_filter=${typeDocByName[document_filter]}&url=${url}`, header)
            .then(response => {
                if(response.status === 200){
                    return response.data;
                }
            })
            .catch(error => {
                console.error(error);
                return {error: error};
            })
        );
    }

}

export default new DocumentViewService();