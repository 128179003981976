import './not-available.css';
import NoContent from '../../icons/no-content.png';
import { useState } from 'react';
import { AboutContent } from '../about-content-component';

export function NotAvailable() {
    const [ openAboutContentSection, setOpenAboutContentSection ] = useState(false);
    return (
        <>
            <AboutContent openAboutContentSection={openAboutContentSection} setOpenAboutContentSection={(e) => setOpenAboutContentSection(e)}/>

            <div className="not-found">
                <h1>Conteúdo não disponível. <a  onClick = {() => setOpenAboutContentSection(true)}>Clique aqui</a> e saiba mais sobre os conteúdos do Busca Sankhya.</h1>
                <img src={NoContent}></img>
            </div>
        </>
    )
}