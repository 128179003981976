interface Props {
    className?: string;
}

export default function IconTecNotes(props: Props) {
    return (
        <svg className={props.className} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M11.4597 4.39187C11.2428 4.39187 11.0635 4.32098 10.9217 4.17919C10.78 4.03741 10.7091 3.85809 10.7091 3.64124C10.7091 3.42439 10.78 3.24507 10.9217 3.10328C11.0635 2.9615 11.2428 2.8906 11.4597 2.8906H18.966C19.1829 2.8906 19.3622 2.9615 19.504 3.10328C19.6458 3.24507 19.7167 3.42439 19.7167 3.64124C19.7167 3.85809 19.6458 4.03741 19.504 4.17919C19.3622 4.32098 19.1829 4.39187 18.966 4.39187H11.4597ZM11.4597 12.3986C11.2428 12.3986 11.0635 12.3278 10.9217 12.186C10.78 12.0442 10.7091 11.8649 10.7091 11.648C10.7091 11.4312 10.78 11.2518 10.9217 11.1101C11.0635 10.9683 11.2428 10.8974 11.4597 10.8974H18.966C19.1829 10.8974 19.3622 10.9683 19.504 11.1101C19.6458 11.2518 19.7167 11.4312 19.7167 11.648C19.7167 11.8649 19.6458 12.0442 19.504 12.186C19.3622 12.3278 19.1829 12.3986 18.966 12.3986H11.4597ZM2.72731 5.76804L0.225191 3.26592C0.0750636 3.11579 0 2.94064 0 2.74048C0 2.54031 0.0750636 2.36516 0.225191 2.21503C0.375318 2.0649 0.550466 1.99401 0.750635 2.00235C0.950804 2.01069 1.12595 2.08158 1.27608 2.21503L3.25275 4.16668L7.2061 0.213338C7.35622 0.063211 7.53137 -0.00768193 7.73154 0.000658455C7.93171 0.00899884 8.10686 0.0882322 8.25698 0.238359C8.40711 0.388486 8.48217 0.563634 8.48217 0.763804C8.48217 0.963973 8.40711 1.13912 8.25698 1.28925L3.7782 5.76804C3.62807 5.91816 3.45292 5.99323 3.25275 5.99323C3.05258 5.99323 2.87743 5.91816 2.72731 5.76804ZM2.72731 13.7748L0.225191 11.2727C0.0750636 11.1226 0 10.9474 0 10.7472C0 10.5471 0.0750636 10.3719 0.225191 10.2218C0.375318 10.0717 0.550466 10.0008 0.750635 10.0091C0.950804 10.0175 1.12595 10.0884 1.27608 10.2218L3.25275 12.1735L7.2061 8.22011C7.35622 8.06998 7.53137 7.99909 7.73154 8.00743C7.93171 8.01577 8.10686 8.09501 8.25698 8.24513C8.40711 8.39526 8.48217 8.57041 8.48217 8.77058C8.48217 8.97075 8.40711 9.14589 8.25698 9.29602L3.7782 13.7748C3.62807 13.9249 3.45292 14 3.25275 14C3.05258 14 2.87743 13.9249 2.72731 13.7748Z" 
                fill="currentColor" 
            />
        </svg>
    );
}
