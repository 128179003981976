import Http from "../commons/utils/Http";
import token from "../commons/utils/token";
import { errorHandling } from "../commons/utils/error";

class OptInService {
    async checkOptIn() {
        const idSession = token.getLocalStorageJWTToken();

        const header = {
            headers: {
                'token':idSession
            }
        }

        return (await Http.head(`/check_opt_in`, header)
        .then(response => {
            return false;
        }).catch(e => {
            return e.response.status === 404 ? true : false;
        })
        );
    } 
    
    async sendOptIn(accept) {
        const data = {
            accepted: accept
        }

        const idSession = token.getLocalStorageJWTToken();

        const header = {
            headers: {
                'token':idSession
            }
        }

        return await Http.post('/register_opt_in', data, header).then(response => {
            return response.data;
        }).catch(e => {
            return errorHandling(e);
        });
    }
}

export default new OptInService();