
import { Link, useLocation } from 'react-router-dom';
import IconHelpCenter from '../../icons/IconHelpCenter';
import './related-content.css';

export function RelatedContent(props: any) {

    const { state } = useLocation();

    const typeDocToName: any = {
        'ART': 'articles',
        'OS': 'os',
        'TCK': 'tickets',
        'NTC': 'notes'
    };

    return (
        <>
            { 'related_docs' in props && props.related_docs.length > 0 &&
            <div className='related-docs'>
                <span className='related-docs-title'>
                    <IconHelpCenter />
                    <h4>Conteúdo relacionado</h4>
                </span>
                <ul className='related-docs-list'>

                    {
                        props.related_docs && props.related_docs.map((doc: any, index: number) => {
                            if (index > 5 ) return;

                            return (
                                <Link 
                                    state={{
                                        query: props.query, 
                                        idSearch: props.idSearch, 
                                        timestampSearch: props.timestampSearch,
                                        prevURL: state.prevURL
                                    }}
                                    title={doc.title} key={`related-doc-${index}`} 
                                    to={`/docs/${typeDocToName[doc.document_filter]}/${encodeURIComponent(doc.url)}`} 
                                    className='related-docs-item'
                                >
                                    {doc.title}
                                </Link>
                            )
                        })
                    }
                </ul>
            </div>
            }
        </>
    );
}